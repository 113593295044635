import React, { useCallback } from 'react'
import moment from 'moment'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Poster } from './components/poster'
import { ContentGetQuery } from 'shared/models/content/get-model'
import { processContentImages } from 'shared/utils/image'
import { Flex } from 'shared/components/flex'
import { List } from 'shared/components/list'
import { LIST_ITEM_HEIGHT } from './ai-list.constants'
import { Ellipsis } from 'shared/components/ellipsis'
import { ROUTES } from 'shared/constants/routes'
import { CONTENT_TYPE_LABELS } from 'shared/constants/content'
import { Link } from 'shared/components/link'
import { ImageOrientation } from 'shared/models/image/orientation'
import { DEFAULT_ITEMS_PER_PAGE } from 'shared/constants/pagination'
import { ContentType } from 'shared/models/content/content-type'

import { styles } from './ai-list.styles'
import { Actions } from './components/actions'
import { ActionsWithAll } from './components/actionsWithAll'

export type ContentListProps = AppWithStyles<typeof styles> & {
  data: Array<ContentGetQuery>
  width: number
  height: number
  query: string
  total: number
  getList: (query: string, page: number) => Promise<void>
}

const AIListComponent: React.FC<ContentListProps> = ({
  classes,
  width,
  height,
  query,
  data,
  getList: _getList,
  total,
}) => {
  const isMobile = window.innerWidth < 980;

  const getList = useCallback(
    (page: number) => {
      return _getList(query, page)
    },
    [query, _getList],
  )

  const renderItem = useCallback(
    (data: ContentGetQuery) => {
      const poster = processContentImages(data?.images)?.POSTER
      const getReleaseDate = () => {
        if (!data?.releaseDate) return null

        const processedReleaseDate = moment(data?.releaseDate).year()
        const config = {
          [ContentType.sport]: data?.seasonYears,
          [ContentType.movie]: processedReleaseDate,
          [ContentType.show]: processedReleaseDate,
        }

        return config[data?.contentType]
      }

      const releaseDate = getReleaseDate()

      const posterProps = {
        id: data?.id,
        url: poster?.url.medium,
        orientation: poster?.orientation || ImageOrientation.portrait,
        classes: {
          root: classes[poster?.orientation || ImageOrientation.portrait],
        },
      }

      return (
        <Link
          href={ROUTES.public.content(encodeURIComponent(data?.id))}
          target="_blank"
          rel="noreferrer noopener"
        >
          <Flex alignItems="center" classes={{ root: classes.listItem }}>
            <Flex
              autoWidth={false}
              alignItems="center"
              justify="center"
              classes={{ root: classes.posterWrapper }}
            >
              <Poster {...posterProps} />
            </Flex>
            <div
              className={
                classes[
                  `info-${poster?.orientation || ImageOrientation.portrait}`
                ]
              }
            >
              <Ellipsis
                withTooltip={false}
                text={data.title}
                classes={{ root: classes.itemTitle }}
              />
              <Flex alignItems="center" autoWidth={false}>
                <div className={classes.itemMetadata}>
                  {CONTENT_TYPE_LABELS[data.contentType]}
                </div>
                {Boolean(releaseDate) && (
                  <div className={classes.itemMetadata}>{releaseDate}</div>
                )}
              </Flex>
              <Ellipsis
                text={data.description}
                withTooltip={false}
                classes={{ root: classes.itemDescription }}
              />
            </div>
            <Actions title={data.title} id={data.id} isMobile={isMobile} />
          </Flex>
        </Link>
      )
    },
    [classes],
  )

  return (
    <div className={classes.root}>
      <List
        idProperty="id"
        width={width}
        height={height}
        initialPage={data.entries.length / DEFAULT_ITEMS_PER_PAGE - 1}
        itemSize={LIST_ITEM_HEIGHT}
        data={data}
        shimmerVerticalPadding={4}
        renderItem={renderItem}
        total={total}
        getData={getList}
      />
      <div className={classes.footer}>
        <ActionsWithAll ids={data.map(d => d.id)} isMobile={isMobile} />
      </div>
    </div>
  )
}

export const AIList = appWithStyles(styles)(AIListComponent)
