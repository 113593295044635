import React, {
  RefObject,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import Grid from '@material-ui/core/Grid'

import { Header } from './components/header'
import { AppWithStyles, appWithStyles } from 'core/theme/utils/with-styles'
import {
  CarouselData,
  CarouselDataItem,
  CarouselItemDimensions,
} from './carousel.types'
import {
  ITEM_HORIZONTAL_PADDING,
  MOBILE_ITEM_HORIZONTAL_PADDING,
} from './carousel.constants'
import { Shimmer } from 'shared/components/shimmer'
import { Flex } from 'shared/components/flex'
import { Item } from './components/item'

import { styles } from './carousel.styles'

export type CarouselProps = AppWithStyles<typeof styles> & {
  initialLoading?: boolean
  showWithErrors?: boolean
  actions?: React.ReactNode
  itemDimensions?: CarouselItemDimensions
  loadingItemDimensions?: CarouselItemDimensions
  idProperty: string
  total?: number
  errorMessage?: React.ReactNode
  title?: React.ReactNode
  loopInterval?: number
  data: CarouselData
  getListRef?: (ref: RefObject<HTMLDivElement>) => void
  getData?: (page: number) => Promise<void>
  renderItem: (data: CarouselDataItem) => React.ReactNode
}

const _Carousel: React.FC<CarouselProps> = ({
  initialLoading,
  showWithErrors,
  actions,
  classes,
  idProperty,
  total,
  itemDimensions,
  loadingItemDimensions: _loadingItemDimensions,
  data,
  renderItem,
  title,
  loopInterval,
  getData: _getData,
  getListRef,
  errorMessage = 'Something went while loading content',
}) => {
  const isMobile = window.innerWidth < 980
  const interval = loopInterval * 1000
  const [page, setPage] = useState(0)
  const [loading, setLoading] = useState(initialLoading || Boolean(_getData))
  const [error, setError] = useState(false)
  const [autoScroll, setAutoScroll] = useState(true)
  const [isIntersecting, setIsIntersecting] = useState(false)
  const [carouselItems, setCarouselItems] = useState([])
  const [disabledScrollControls, setDisabledScrollControls] = useState({
    left: true,
    right: false,
  })
  const rootRef = useRef<HTMLDivElement>(null)
  const loadingRef = useRef<HTMLDivElement>()

  const isAutoscrollEnabled =
    !loading && loopInterval && autoScroll && isIntersecting

  const loadingItemDimensions = useMemo(() => {
    return _loadingItemDimensions || itemDimensions
  }, [_loadingItemDimensions, itemDimensions])

  useEffect(() => {
    setLoading(initialLoading)
  }, [initialLoading])

  const getData = useCallback(
    async (_page: number) => {
      try {
        setLoading(true)

        await _getData(_page)
      } catch (err) {
        setError(true)

        console.error(err)
      } finally {
        setLoading(false)
      }
    },
    [_getData],
  )

  useEffect(() => {
    if (_getData) {
      getData(0)
    }

    if (getListRef) {
      getListRef(rootRef)
    }
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    const observer = new IntersectionObserver(([entry]) => {
      setIsIntersecting(entry.isIntersecting)
    })
    observer.observe(rootRef.current)
    return () => observer.disconnect()
  }, [])

  useEffect(() => {
    if (isAutoscrollEnabled) {
      const myInterval = setInterval(handleAutoScroll, interval)

      return () => clearInterval(myInterval)
    }
  }, [isAutoscrollEnabled])

  const getCurrentScrollWidth = () => {
    if (rootRef.current) {
      return rootRef.current.scrollLeft + rootRef.current.clientWidth
    }
  }

  const getScrollClickStepWidth = useCallback(() => {
    if (!itemDimensions) {
      return rootRef.current.offsetWidth
    }

    if (rootRef.current) {
      const computedItemWidth = isMobile
        ? itemDimensions.width + MOBILE_ITEM_HORIZONTAL_PADDING
        : itemDimensions.width + ITEM_HORIZONTAL_PADDING

      return loopInterval
        ? computedItemWidth
        : Math.floor(rootRef.current.offsetWidth / computedItemWidth) *
            computedItemWidth
    }
  }, [itemDimensions, loopInterval])

  const getMore = useCallback(() => {
    const loadingItem = loadingRef.current?.getBoundingClientRect()

    if (loadingItem && !loading) {
      const isInViewPort = loadingItem.left <= rootRef.current.offsetWidth
      if (isInViewPort && data.length < total) {
        const nextPage = page + 1

        setPage(nextPage)
        getData(nextPage)
      }
    }
  }, [getData, loading, data, page, total])

  const handleScrollLeft = useCallback(() => {
    setAutoScroll(false)
    const currentScrollLeft =
      rootRef.current.scrollLeft - getScrollClickStepWidth()

    rootRef.current.scrollTo({
      left: currentScrollLeft,
      top: 0,
      behavior: 'smooth',
    })

    setDisabledScrollControls(prevProps => ({
      ...prevProps,
      left: currentScrollLeft === 0,
    }))
  }, [getScrollClickStepWidth])

  const handleScrollRight = useCallback(() => {
    setAutoScroll(false)
    const currentScrollLeft =
      rootRef.current.scrollLeft + getScrollClickStepWidth()

    rootRef.current.scrollTo({
      left: currentScrollLeft,
      top: 0,
      behavior: 'smooth',
    })

    setDisabledScrollControls(prevProps => ({
      ...prevProps,
      left: currentScrollLeft === 0,
      right:
        rootRef.current.scrollLeft ===
        rootRef.current.clientWidth - rootRef.current.offsetWidth,
    }))
  }, [getScrollClickStepWidth])

  const handleAutoScroll = () => {
    const currentScrollLeft =
      rootRef.current.scrollLeft + getScrollClickStepWidth()

    rootRef.current.scrollTo({
      left: currentScrollLeft,
      top: 0,
      behavior: 'smooth',
    })
  }

  const handleScroll = () => {
    const currentScrollLeft = rootRef.current?.scrollLeft
    getMore()

    if (
      loopInterval &&
      getCurrentScrollWidth() >=
        rootRef.current?.scrollWidth - getCurrentScrollWidth()
    ) {
      setCarouselItems(prevState => {
        return [...prevState, ...data]
      })
    }

    setDisabledScrollControls(prevProps => ({
      ...prevProps,
      left: currentScrollLeft === 0,
      right: getCurrentScrollWidth() === rootRef.current?.scrollWidth,
    }))
  }

  useEffect(() => {
    setCarouselItems(data)

    const currentScrollLeft = rootRef.current?.scrollLeft

    setDisabledScrollControls(prevProps => ({
      ...prevProps,
      left: currentScrollLeft === 0,
      right: getCurrentScrollWidth() === rootRef.current?.scrollWidth,
    }))
  }, [data])

  useEffect(() => {
    if (error) {
      setDisabledScrollControls({
        left: true,
        right: true,
      })
    }
  }, [error])

  const loadingItems = useMemo(() => {
    if (data.length === total || !total) {
      return []
    }

    const rowAmountOfItems = rootRef.current?.offsetWidth
      ? Math.floor(rootRef.current?.offsetWidth / loadingItemDimensions.width)
      : 10

    return Array(rowAmountOfItems * 2).fill('')
  }, [data, loadingItemDimensions, total])

  const getContent = () => {
    if (error) {
      return (
        <Flex
          alignItems="center"
          justify="center"
          classes={{ root: classes.errorState }}
        >
          {errorMessage}
        </Flex>
      )
    }

    return (
      <Grid
        container
        wrap="nowrap"
        alignItems="flex-end"
        spacing={isMobile ? 4 : 10}
        classes={{ root: classes.list }}
        style={{
          overflow: isMobile ? 'auto' : 'hidden',
        }}
        ref={rootRef}
        onScroll={handleScroll}
      >
        {loopInterval &&
          carouselItems.map((item, index) => {
            return (
              <Item
                dimensions={itemDimensions}
                key={`${item[idProperty]}_${index}`}
                data={item}
                renderItem={renderItem}
                classes={{ root: classes.item }}
              />
            )
          })}

        {!loopInterval &&
          data.map(item => {
            return (
              <Item
                dimensions={itemDimensions}
                key={item[idProperty]}
                data={item}
                renderItem={renderItem}
                classes={{ root: classes.item }}
              />
            )
          })}
        {loading &&
          loadingItems.map((x, i) => {
            return (
              <Grid item ref={i === 0 ? loadingRef : undefined} key={i}>
                <Shimmer
                  itemsAmount={1}
                  containerProps={{
                    height: '100%',
                    width: '100%',
                  }}
                  itemProps={loadingItemDimensions}
                />
              </Grid>
            )
          })}
      </Grid>
    )
  }

  if (!showWithErrors && error) {
    return null
  }

  return (
    <div className={classes.root}>
      <Header
        title={title}
        actions={actions}
        disabledScrollControls={disabledScrollControls}
        classes={{
          root: classes.header,
          title: classes.title,
          actions: classes.actions,
        }}
        onScrollLeft={handleScrollLeft}
        onScrollRight={handleScrollRight}
      />
      {getContent()}
    </div>
  )
}

_Carousel.defaultProps = {
  showWithErrors: true,
}

export const Carousel = appWithStyles(styles)(React.memo(_Carousel))
