import React, { useEffect, useMemo } from 'react'

import { NavLink, Link } from 'react-router-dom'
import Logo from '../Logo'
import ButtonDefault from 'components/Buttons/Default'
import { Avatar } from 'shared/components/avatar'
import { ROUTES } from 'shared/constants/routes'
import { appObserver } from 'core/state-management/utils'
import { GlobalSearch } from '../search-modal/components/filters/global-search/global-search'

import { AppWithStyles, appWithStyles } from 'core/theme/utils/with-styles'
import { styles } from './header.styles'
import { Announcements } from './components/announcements/announcements'
import { MobileHeader } from 'components/mobile-header/mobile-header'
import { HeaderViewModel } from './header.vm'
import { Application } from 'services'
import { Banner } from 'components/banner/banner'
import { toJS } from 'mobx'

export type Props = AppWithStyles<typeof styles>

const HeaderComponent: React.FC<Props> = ({ classes }) => {
  const $app = Application.instance()
  const $vm = useMemo(() => new HeaderViewModel(), [])

  const isLoggedIn = $app.auth.isLoggedIn()

  useEffect(() => {
    if (isLoggedIn) {
      $app.user.initUser()
    }
  }, [isLoggedIn])

  const headerNavigationList = $vm.navigationListData.map(item => (
    <li key={item.name}>
      <NavLink
        exact
        to={item.to}
        className="nav-link fz-16 white-opacity-04"
        activeClassName="active"
      >
        {item.name}
      </NavLink>
    </li>
  ))

  const isMobile = window.innerWidth < 980

  const handleSignInClick = () => {
    $app.event.$emit('overlay.activity.login', {
      status: true,
    })
  }

  const handleSignUpClick = () => {
    $app.event.$emit('overlay.activity.login', {
      status: true,
      payload: 1,
    })
  }

  const handleBannerClose = () => {
    $vm.handleBannerClose()
  }

  return (
    <>
      {typeof $vm.isBannerOpen == 'boolean' &&
        $vm.isBannerOpen &&
        $vm.banner && (
          <Banner banner={$vm.banner} handleClose={handleBannerClose} />
        )}
      {!isMobile && (
        <div className="header container">
          <div className="header-left-side">
            <Logo />
            <ul className="header-nav">{headerNavigationList}</ul>
          </div>
          <div className="header-right-side">
            <GlobalSearch classes={{ root: classes.search }} />
            <Announcements />
            {$app.auth.isLoggedIn() ? (
              <>
                <Link to={ROUTES.private.profile.root($app.auth.user.id)}>
                  <Avatar
                    classes={{ root: classes.avatar }}
                    src={$app.auth.user.avatar}
                  />
                </Link>
                <ButtonDefault
                  type="link"
                  color="gray"
                  customClass="more"
                  style={{ marginRight: 0 }}
                  onClick={$app.auth.signOut}
                >
                  <span className="fz-14 white-opacity-04">Sign Out</span>
                </ButtonDefault>
              </>
            ) : (
              <>
                <ButtonDefault
                  onClick={handleSignInClick}
                  bgColor="gray"
                  type="rounded"
                  disabled={$app.auth.isAuthInProgress()}
                >
                  <div className="fz-16 white-opacity-04">Sign In</div>
                </ButtonDefault>

                <ButtonDefault
                  bgColor="green"
                  color="white"
                  type="rounded"
                  disabled={$app.auth.isAuthInProgress()}
                  onClick={handleSignUpClick}
                >
                  Sign Up
                </ButtonDefault>
              </>
            )}
          </div>
        </div>
      )}
      {isMobile && <MobileHeader />}
    </>
  )
}

export const Header = appWithStyles(styles)(appObserver(HeaderComponent))
