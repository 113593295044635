import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { Search as SearchIcon } from '@material-ui/icons'
import { Application } from 'services'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Button } from 'shared/components/button'
import { appObserver } from 'core/state-management/utils'
import { GlobalSearchViewModel } from '../model/global-search.vm'
import { SearchModal } from 'components/search-modal/search-modal'

import { styles } from './global-search.styles'

export type SearchProps = AppWithStyles<typeof styles>

const GlobalSearchComponent: React.FC<SearchProps> = appObserver(
  ({ classes }) => {
    const $app = Application.instance()
    const $vm = useMemo(() => new GlobalSearchViewModel(), [])
    const [isModalOpen, setModalOpen] = useState(false)

    const searchParams = new URLSearchParams(window.location.search)
    const searchValue = searchParams.get('query')

    useEffect(() => {
      if (searchValue) {
        setModalOpen(true)
      }
    }, [searchValue])

    const handleModelOpenStateChange = useCallback(() => {
      setModalOpen(!isModalOpen)
      const scrollArea = document.querySelector<HTMLElement>('.app-container')
      isModalOpen
        ? (scrollArea.style.overflowY = 'auto')
        : (scrollArea.style.overflowY = 'hidden')
    }, [isModalOpen])

    return (
      <>
        <div className={classes.searchWrapper}>
          <div className={classes.aiOption}>AI powered</div>
          <Button
            startIcon={<SearchIcon />}
            text={`Search content, people${$vm.isLoggedIn ? ', users' : ''}`}
            classes={{ root: classes.root }}
            onClick={handleModelOpenStateChange}
          />
        </div>

        {isModalOpen && (
          <SearchModal
            isLoggedIn={$vm.isLoggedIn}
            onClose={handleModelOpenStateChange}
            paramsValue={searchValue}
          />
        )}
      </>
    )
  },
)

export const GlobalSearch = appWithStyles(styles)(GlobalSearchComponent)
