import React from 'react'
import PlayArrowIcon from '@material-ui/icons/PlayArrow'
import cx from 'classnames'

import { Application } from 'services'
import AddCustom from 'components/AddCustom'
import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'
import { Flex } from 'shared/components/flex'
import { ListType } from 'shared/models/content/list-type'
import { Button } from 'shared/components/button'
import { appObserver } from 'core/state-management/utils'
import { ContentGetQuery } from 'shared/models/content/get-model'

import { styles } from './actions.styles'

export type ActionsProps = AppWithStyles<typeof styles> & {
  id: ContentGetQuery['id']
  title: ContentGetQuery['title']
  isMobile: boolean
}

const ActionsComponent: React.FC<ActionsProps> = appObserver(
  ({ classes, id, title, isMobile }) => {
    const $app = Application.instance()

    const getWatchlistState = () =>
      $app.store.DefaultGridStore.watchLater.includes(id)

    const handleActionClick = e => {
      e.preventDefault();
      const { type } = e.currentTarget.dataset

      const isLoggedIn = $app.auth.isLoggedIn()

      const actionsConfig = {
        [ListType.watchLater]: () =>
          getWatchlistState()
            ? $app.store.DefaultGridStore.removeFromWatchLater(id)
            : $app.store.DefaultGridStore.addToWatchLater(id),
      }

      if (isLoggedIn) {
        actionsConfig[type]()

        return
      }

      $app.event.$emit('overlay.activity.login', {
        status: true,
      })
    }

    const getActionsConfig = () => {
      return [
        {
          listType: ListType.watchLater,
          icon: <PlayArrowIcon />,
          text: 'Watchlist',
          active: getWatchlistState(),
        },
      ]
    }

    return (
      <Flex
        alignItems="center"
        justify="space-between"
        classes={{ root: classes.root }}
      >
        <AddCustom
          isAiAction
          details
          model={{ title, id }}
          isMobile={isMobile}
        />
        {getActionsConfig().map(({ listType, icon, text, active }) => {
          return isMobile ? (
            <div className={classes.buttonWrapper}>
              <Button
                key={listType}
                data-type={listType}
                classes={{
                  root: cx(
                    classes.action,
                    { [classes.actionActive]: active },
                    { [classes.actionMobile]: isMobile },
                  ),
                }}
                onClick={handleActionClick}
              >
                {icon}
              </Button>
              <p className={classes.buttonText}>{text}</p>
            </div>
          ) : (
            <Button
              key={listType}
              startIcon={icon}
              text={text}
              data-type={listType}
              classes={{
                root: cx(classes.action, { [classes.actionActive]: active }),
              }}
              onClick={handleActionClick}
            />
          )
        })}
      </Flex>
    )
  },
)

export const Actions = appWithStyles(styles)(ActionsComponent)
