export const CONTENT_PADDING = 12
export const COUNTER_HEIGHT = 40
export const AI_MODAL_FOOTER_HEIGHT = 64;
export const AI_MOBILE_MODAL_FOOTER_HEIGHT = 80;
export const AI_SCROLLBAR_WIDTH = 10;

export interface ISearchAiTemplate {
  text: string,
  width: number,
  hint: string | null,
  value: string,
}

export const similarToAiTemplate: ISearchAiTemplate = { text: 'similar to', width: 102, hint: 'Star Wars', value: 'similar to' };

export const templatesList: ISearchAiTemplate[] = [
  { text: 'about', width: 78, hint: 'racing', value: 'about' },
  { text: 'with', width: 68, hint: 'Jack Nicholson', value: 'with' },
  similarToAiTemplate,
  {
    text: 'where',
    width: 80,
    hint: 'woman becomes a superhero',
    value: 'where',
  },
  { text: 'for', width: 58, hint: 'a family movie night', value: 'for' },
  {
    text: 'something else you`re looking for',
    width: 36,
    hint: null,
    value: '',
  },
];
