import { AppTheme } from 'core/theme/types/main'
import { appCreateStyles } from 'core/theme/utils/create-styles'
import { appFade } from 'core/theme/utils/fade'

export function styles({ palette, custom }: AppTheme) {
  return appCreateStyles({
    root: {
      backgroundColor: custom.colors.gray[600],
      color: appFade(palette.common.white, 0.4),
      border: `1px solid transparent`,

      '&:hover': {
        backgroundColor: custom.colors.gray[600],
        borderColor: palette.primary.main,
      },
    },
    searchWrapper: {
      position: 'relative',
    },
    aiOption: {
      position: 'absolute',
      background: '#75B952',
      color: '#fff',
      padding: '2px 8px',
      borderRadius: '4px 4px 0px 0px',
      top: '-14.5px',
      left: '30px',
      fontSize: 10,
    },
  })
}
