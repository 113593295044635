import React, { useCallback, useMemo } from 'react'
import cx from 'classnames'
import IconButton from '@material-ui/core/IconButton'
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@material-ui/core/TextField'
import CloseIcon from '@material-ui/icons/Close'

import { appWithStyles, AppWithStyles } from 'core/theme/utils/with-styles'

import { styles } from './text-field-styles'

export type TextFieldProps = AppWithStyles<typeof styles> &
  Except<MuiTextFieldProps, 'onChange'> & {
    clearable?: boolean
    onChange?: (value: string, e) => void
    onKeyDown?: (value: string, e) => void
    allBorder?: boolean
  }

const TextFieldComponent: React.FC<TextFieldProps> = ({
  classes,
  clearable,
  onChange,
  onKeyDown,
  value,
  variant,
  InputProps,
  allBorder,
  ...otherProps
}) => {
  const handleChange = useCallback(
    e => {
      if (onChange) {
        onChange(e.target.value, e)
      }
    },
    [onChange],
  )

  const handleReset = useCallback(
    e => {
      e.stopPropagation()
      e.preventDefault()

      if (onChange) {
        onChange('', e)
      }
    },
    [onChange],
  )

  const endAdornment = useMemo(() => {
    if (clearable && allBorder) {
      return (
        <IconButton
          classes={{ root: classes.clearButton }}
          onClick={handleReset}
        >
          <CloseIcon classes={{ root: classes.clearIcon }} />
        </IconButton>
      )
    }

    return InputProps?.endAdornment
  }, [
    clearable,
    allBorder,
    InputProps,
    classes.clearButton,
    classes.clearIcon,
    handleReset,
  ])

  const InternalInputProps: Partial<MuiTextFieldProps['InputProps']> =
    useMemo(() => {
      return {
        endAdornment,
        classes: {
          root: cx(
            classes.input,
            allBorder ? classes.border1 : classes.border2,
          ),
          focused: allBorder ? null : classes.focused,
          notchedOutline: allBorder
            ? classes.notchedOutline
            : classes.borderNone,
        },
      }
    }, [endAdornment, classes])

  return (
    <MuiTextField
      variant={variant}
      value={value}
      InputProps={{ ...InternalInputProps, ...InputProps }}
      classes={{
        root: classes.root,
      }}
      onChange={handleChange}
      onKeyDown={onKeyDown}
      {...otherProps}
    />
  )
}

export const TextField = appWithStyles(styles)(TextFieldComponent)
