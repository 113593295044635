import { inject } from 'core/di/di-utils'
import { DI_TOKENS } from 'shared/constants/di'
import { ISearchService, SearchResponse } from 'shared/types/services/search'

export class SearchModalViewModel {
  private searchService = inject<ISearchService>(DI_TOKENS.searchService)

  searchContent: ISearchService['searchContent'] = (...args) => {
    return this.searchService.searchContent(...args)
  }

  searchPersons: ISearchService['searchPersons'] = (...args) => {
    return this.searchService.searchPersons(...args)
  }

  searchUsers: ISearchService['searchUsers'] = (...args) => {
    return this.searchService.searchUsers(...args)
  }

  searchAI: ISearchService['searchAI'] = (...args) => {
    return this.searchService.searchAI(...args)
  }
}

export type { SearchResponse }
